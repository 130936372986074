<template>
  <li class="block-product">
    <figure>
      <div class="imgwrap">
        <!-- <img :src="product.image_cover || product.product_images[0].url" alt=""> -->
        <img :src="getCategoryCoverImage" alt="cover" @error="imageError($event)" />
      </div>
    </figure>
  </li>
</template>

<script>
export default {
  name: 'categoryCard',
  props: ['product', 'category'],
  data() {
    return {
      isRecomendedMaskLoading: false,
      recomendedMask: '',
    };
  },
  computed: {
    getCategoryCoverImage() {
      if ('category_image' in this.category && this.category.category_image && this.category.category_image !== '') {
        return this.category.category_image;
      }
      return require('static/img/icons/dummy.svg');
    },
  },
  created() {},
  methods: {
    imageError(e) {
      e.target.src = '/static/img/icons/dummy.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'categoryCard';
</style>
